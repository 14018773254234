import React from "react"
import $ from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Clutch from "../components/clutch"
import Slider from "react-slick"
import Women__gloves from "../images/ww-gloves.png"
import Women__hat from "../images/ww-hat.png"
import Slider_img1 from "../images/ww-slide-img-1.jpg"
import Slider_img2 from "../images/ww-slide-img-2.jpg"
import Slider_img3 from "../images/ww-slide-img-3.jpg"
import Right_arrow from "../images/ww-right-arrow-black.png"
import Bag_img from "../images/ww-bag-img.png"
import Case_logo from "../images/ww-case-logo.png"
import Case_approach from "../images/ww-approach-img.png"
import Case_points from "../images/ww-points-img-1.png"
import Case_points2 from "../images/ww-points-img-2.png"
import Case_points3 from "../images/ww-points-img-3.png"
import Case_points4 from "../images/ww-points-img-4.png"
import Arrow_wh from "../images/ww-right-arrow-white.png"
import Pink_arrow from "../images/ww-pink-arrow.png"
import ipad_canvas from "../images/ww-ipad-canvas.png"
import Scroll_top_img from "../images/scroll-arrow.png"
import {Helmet} from "react-helmet/es/Helmet"

import "animate.css/animate.min.css"
import ScrollAnimation from 'react-animate-on-scroll'

const casestudy__slider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000
};


export default () => (
    <Layout>
        <Helmet>
            <title>CueBlocks Team - Ecommerce Experts behind the Scenes</title>
            <meta name="description"
                  content="CueBlocks has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
            <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
            <link rel="canonical" href="https://www.cueblocks.com/team.php"/>
            <meta name="description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta property="og:site_name" content="Cueblocks"/>
            <meta property="fb:app_id" content="289086684439915"/>
            <meta property="og:url" content="https://www.cueblocks.com/team.php"/>
            <meta property="og:title"
                  content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
            <meta property="og:description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta name="twitter:title"
                  content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
            <meta name="twitter:site" content="@cueblocks"/>
            <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
        </Helmet>
        <div className="cb_expertiseInner">
            <div className=" case_study_page ">
                <div className="case-wrap ">
                    <div className="ww-sec-one ">
                        <div className="container">

                            <h3>Case Study</h3>
                            <ScrollAnimation animateIn="slideInUp" animateOnce="true">
                            <h1>WOMANSWORK</h1>
                            </ScrollAnimation>
                            <p className="ft-sndry sitespeed_p">How CueBlocks helped WomansWork speed up their Magento 2 store</p>

                            <div className="ww-top-img "> 
                                <img src={Women__gloves} alt="Womanswork" className="img-adjust" />
                            </div>
                            <div className="ww-main-heading">Our team assisted the women-owned family business to reduce their first load time by
                                65.87% and repeat view time by 67.65%.</div>

                            <div className="ww-main-points">
                                <div className="ww-tick-img"><img src={Pink_arrow} alt="Womanswork" className="img-adjust" /></div>
                                <ul>
                                    <li>Average first load speed <br />increased by <span>65.87%</span></li>
                                    <li>Average repeat view speed <br />increased by <span>67.65%</span></li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="ww-sec-about ww-sec-common ">
                        <div className="container">

                            <div className="ww-about-inner ">
                                <div className="ww-about-cont ">
                                    <div className="ww-about-left">
                                        <h2  className="heading_main">About the <br />client</h2>
                                        <p>WomansWork has been selling high quality gardening related accessories - gloves, tools, skin care etc.,
                                          since 1986. Their love for gardening, ever available customer support and high standard of their products
                                            got them covered by magazines like Vogue, Better Homes, The O List (Oprah) and many more.</p>
                                    </div>
                                    <div className="ww-about-right">
                                        <span>
                                            <ScrollAnimation animateIn="slideInDown" animateOnce="true">
                                            <img src={Women__hat} alt="Womanswork" className="img-adjust" />
                                            </ScrollAnimation>
                                            </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="ww-sec-client ww-sec-common ">
                        <div className="container">
                            <div className="ww-client-inner ">

                                <div className="ww-client-left">
                                    <div className="ww-client-slider">
                                        <img src={ipad_canvas} alt="Womanswork" /> 
                                       
                                        <ul>
                                            <Slider {...casestudy__slider}>
                                            <li><img src={Slider_img1} alt="Womanswork"  /></li>
                                            <li><img src={Slider_img2} alt="Womanswork" /></li>
                                            <li><img src={Slider_img3} alt="Womanswork" /></li>
                                            </Slider>
                                        </ul>
                                       
                                    </div>
                                </div>
                                <div className="ww-client-right">
                                    <h2 className="heading_main">Client's <br />Requirement</h2>
                                    <p>Since upgrading to Magento 2, a slow site speed was a pressing issue for WomansWork. The long loading times
                                      were a big deterrent to a crisp and seamless user experience. With an optimally performing and a quicker
                                      website, the client aimed at leveraging the buzz they were successfully able to create with their products
                                        and novel ideas like their <a href="https://womanswork.com/about_us" >'Story Bank'.</a></p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="ww-sec-result ww-sec-common ">
                        <div className="container">

                            <div className="ww-table-info ww-result-info ">
                                <h2 className="heading_main">Results</h2>
                                <p>Fixes/ recommendations made by CueBlocks resulted in decreasing the pagespeed across the website, which has
                                lead to considerable improvement to the user experience.</p>
                                <h3>Home Page</h3>
                                <p><a href="https://womanswork.com" >womanswork.com</a></p>

                                <table>
                                    <thead>
                                        <tr>
                                            <td><span>7<sup>th</sup> june 2018</span></td>
                                            <td>&nbsp;</td>
                                            <td><span>8<sup>th</sup> jan 2019</span></td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr className="extra">
                                            <td colSpan="3" height="50"></td>
                                        </tr>
                                        <tr>
                                            <td><span>8.734<sub>s</sub> <u>First View</u></span></td>
                                            <td rowSpan="2"><img src={Arrow_wh} alt="Womanswork" /> </td>
                                            <td className="right"><span>2.993<sub>s</sub> <u>First View</u></span></td>
                                        </tr>
                                        <tr>
                                            <td><span>9.199<sub>s</sub> <u>Repeat View</u></span></td>
                                            <td className="right"><span>2.395<sub>s</sub> <u>Repeat View</u></span></td>
                                        </tr>
                                        <tr className="extra">
                                            <td colSpan="3" height="50"></td>
                                        </tr>
                                        <tr className="highlight-row">
                                            <td colSpan="3"><span>Decreased load time by</span></td>
                                        </tr>
                                        <tr className="extra">
                                            <td colSpan="3" height="30"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="progress-bar right p1"><span className="sixty"></span></div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>
                                                <div className="progress-bar left p2"><span className="seventy"></span></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span>65.73% <u>First View</u></span></td>
                                            <td>&nbsp;</td>
                                            <td className="right"><span>73.96% <u>Repeat View</u></span></td>
                                        </tr>
                                        <tr className="extra">
                                            <td colSpan="3" height="50"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                    <div className="ww-sec-category ww-sec-common ">
                        <div className="container">

                            <div className="ww-table-info ww-category-info ">
                                <h3 className="heading_main">Category Page</h3>
                                <p><a href="https://womanswork.com/catalog/womens-gloves" >Womens Gloves</a></p>

                                <table>
                                    <thead>
                                        <tr>
                                            <td><span>7<sup>th</sup> june 2018</span></td>
                                            <td>&nbsp;</td>
                                            <td><span>8<sup>th</sup> jan 2019</span></td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr className="extra">
                                            <td colSpan="3" height="50"></td>
                                        </tr>
                                        <tr>
                                            <td><span>9.624<sub>s</sub> <u>First View</u></span></td>
                                            <td rowSpan="2"><img src={Right_arrow} alt="Womanswork" /></td>
                                            <td className="right"><span>3.343<sub>s</sub> <u>First View</u></span></td>
                                        </tr>
                                        <tr>
                                            <td><span>8.930<sub>s</sub> <u>Repeat View</u></span></td>
                                            <td className="right"><span>2.755<sub>s</sub> <u>Repeat View</u></span></td>
                                        </tr>
                                        <tr className="extra">
                                            <td colSpan="3" height="50"></td>
                                        </tr>
                                        <tr className="highlight-row">
                                            <td colSpan="3"><span>Decreased load time by</span></td>
                                        </tr>
                                        <tr className="extra">
                                            <td colSpan="3" height="30"></td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div className="progress-bar right p3"><span className="sixty"></span></div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>
                                                <div className="progress-bar left p4"><span className="seventy"></span></div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td><span>65.26% <u>First View</u></span></td>
                                            <td>&nbsp;</td>
                                            <td className="right"><span>69.14% <u>Repeat View</u></span></td>
                                        </tr>
                                        <tr className="extra">
                                            <td colSpan="3" height="50"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                    <div className="ww-sec-product ww-sec-common ">
                        <div className="container">

                            <div className="ww-table-info ww-product-info ">
                                <h3 className="heading_main">Product Page</h3>
                                <p><a href="https://womanswork.com/purple-floral-arm-saver-gloves.html" >Purple Floral Arm Saver
                                    Gloves</a></p>

                                <div className="ww-table-bag-wrap">
                                    <table>
                                        <thead>
                                            <tr>
                                                <td><span>7<sup>th</sup> june 2018</span></td>
                                                <td>&nbsp;</td>
                                                <td><span>8<sup>th</sup> jan 2019</span></td>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr className="extra">
                                                <td colSpan="3" height="50"></td>
                                            </tr>
                                            <tr>
                                                <td><span>14.743<sub>s</sub> <u>First View</u></span></td>
                                                <td rowSpan="2"><img src={Right_arrow} alt="Womanswork" /></td>
                                                <td className="right"><span>4.920<sub>s</sub> <u>First View</u></span></td>
                                            </tr>
                                            <tr>
                                                <td><span>9.838<sub>s</sub> <u>Repeat View</u></span></td>
                                                <td className="right"><span>3.966<sub>s</sub> <u>Repeat View</u></span></td>
                                            </tr>
                                            <tr className="extra">
                                                <td colSpan="3" height="50"></td>
                                            </tr>
                                            <tr className="highlight-row">
                                                <td colSpan="3"><span>Decreased load time by</span></td>
                                            </tr>
                                            <tr className="extra">
                                                <td colSpan="3" height="30"></td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className="progress-bar right p5"><span className="seventy"></span></div>
                                                </td>
                                                <td>&nbsp;</td>
                                                <td>
                                                    <div className="progress-bar left p6"><span className="sixty"></span></div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td><span>66.62% <u>First View</u></span></td>
                                                <td>&nbsp;</td>
                                                <td className="right"><span>59.68% <u>Repeat View</u></span></td>
                                            </tr>
                                            <tr className="extra">
                                                <td colSpan="3" height="50"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                   
                                    <span className="ww-product-img"> 
                                        <ScrollAnimation animateIn="slideInRight" animateOnce="true" ><img src={Bag_img} alt="Womanswork" />
                                        </ScrollAnimation>
                                    </span>
                                   
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="ww-sec-approach ww-sec-common ">
                        <div className="container">

                            <div className="ww-approach-inner">
                                <h2 className="heading_main">Our Approach</h2>

                                <div className="ww-approach-left ww-approach-inner-div">
                                    <div className="ww-logo"><img src={Case_logo} alt="Womanswork" /></div>
                                    <p>As is the standard operating procedure at CueBlocks, we broke down the requirement into smaller sub tasks -
                our top priority being improving the speed and performance of the website.</p>
                                    <br />
                                    <p>The following are some of the major changes made or recommended by CueBlocks in order to make a positive
                impact on the speed and performance of WomansWork.com:</p>

                                    <ul>
                                        <li>CSS/ JS Minification (Stopped JS Bundling).</li>
                                        <li>Implemented Server Cache - Redis + Memcache.</li>
                                        <li>Fixed Full Page Cache (FPC) issue which ensured its proper functioning.</li>
                                        <li>Switched on the production mode.</li>
                                    </ul>
                                </div>

                                <div className="ww-approach-right ww-approach-inner-div">
                                    <p>On analysing the health of the website through a code audit and using diagnostic tools such as Pingdom,
                                      GTmetrix and Webpage speed test, we could identify several prominent deviations from the recommended Magento
                                      best practices. We identified improvements which, if implemented correctly, could have lead to a notable
                                        change in the site speed and performance.</p>
                                    <div className="ww-approach-img"><img src={Case_approach} alt="Womanswork" /></div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="ww-sec-points ">
                        <div className="container">
                            <h2>Other important recommendations and implementations made by CueBlocks</h2>

                            <ul>
                                <li>
                                    <span><img src={Case_points} alt="Womanswork" /></span>
                                    <p>Setting up a proper development environment including separate development and staging servers along with a
                                      versioning system. This meant all the code was first to be implemented on a staging server and tested. Only
                                    after approval from the QA and the client the code was pushed live.</p>
                                </li>
                                <li>
                                    <span><img src={Case_points2} alt="Womanswork" /> </span>
                                    <p>Upgrading Magento to the latest 2.2.5 version. There was a big gap between the existing 2.1.2 version and
                                      the upgraded 2.2.5 version. The upgrade hence helped us to introduce the latest (stabler) Magento
                                     optimization standards to the website.</p>
                                </li>
                                <li>
                                    <span><img src={Case_points3} alt="Womanswork" /></span>
                                    <p>Upgrading server to ensure enough space for implementing caching modules.</p>
                                </li>
                                <li>
                                    <span><img src={Case_points4} alt="Womanswork" /></span>
                                    <p>Upgrading PHP to the latest stable versions.</p>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className="get-in-touch-casestudy-section">
                        <h3>Reach out to us to understand<br/> how we can add value to your business.</h3>
                            <a href="/contact" className="main_cta">Get In Touch</a>
                        </div>

                        <div id="scroll-to" className="case-scroll">
                        <a href="#" title="Scroll To Top"><img src={Scroll_top_img} alt="Scroll To Top" /></a>
                        </div>
                    </div>

            </div>
        </div>

            <Clutch />

    </Layout>

        )